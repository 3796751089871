import React from "react";
import { css } from "@emotion/react";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import {
    fontSizeHeader,
    fontSizeText,
    centeredMaxWidthSpacing,
} from "../style/shared";
import { white } from "../style/colors";
import { fontSize18 } from "../style/fonts";
import { halfMaxContentWidth } from "../style/constants";

const headerStyle = css`
    color: ${white};
    ${fontSizeHeader};
    margin-bottom: 32px;
`;

const descriptionStyle = css`
    ${fontSizeText};
    margin-bottom: 64px;
`;

const imgStyle = css`
    position: absolute !important;
    right: calc(45% - ${halfMaxContentWidth}px);
    bottom: -33px;
    height: 33vw !important;
    width: calc(30vw * 1.5) !important;
`;

const sectionStyle = css`
    background-color: #0e2a67;
    position: relative;
    overflow: hidden;
`;

const textStyle = css`
    position: relative;
    z-index: 1;
    padding-top: 64px;
    padding-left: ${centeredMaxWidthSpacing};

    padding-right: calc(81% - ${halfMaxContentWidth}px);
    min-height: 33vw;
    color: ${white};
`;

const subtitleStyle = css`
    ${fontSize18};
    font-style: italic;
    color: ${white};
    position: absolute;
    right: calc(45% - 500px);
    bottom: 16px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
`;

export default () => {
    const data = useStaticQuery(graphql`
        query {
            castel: file(
                relativePath: {
                    eq: "O_4a76b80a-5cf1-4f91-8fdf-2e2096899d00.jpg"
                }
            ) {
                childImageSharp {
                    fixed(height: 600) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <section css={sectionStyle}>
            <Img css={imgStyle} fixed={data.castel.childImageSharp.fixed} />
            <p css={subtitleStyle}>Wasserburg Bad Vilbel</p>
            <div css={textStyle}>
                <h1 css={headerStyle}>Die Kanzlei</h1>
                <p css={descriptionStyle}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Duis aliquet nunc suscipit, vestibulum dui sed, varius
                    libero. Nunc pellentesque velit in purus maximus facilisis.
                    Quisque porta ex et bibendum venenatis. Mauris ac quam sed
                    elit scelerisque accumsan. Vivamus lobortis vulputate
                    libero, sed fermentum lorem. Praesent sed pellentesque
                    dolor. Aliquam et est ac nibh rhoncus consectetur.
                    Suspendisse id sem eu neque mollis feugiat quis vel elit.
                    Vivamus consequat dictum libero eget feugiat.
                </p>
                <p css={descriptionStyle}>
                    Vivamus lobortis vulputate libero, sed fermentum lorem.
                    Praesent sed pellentesque dolor. Aliquam et est ac nibh
                    rhoncus consectetur. Suspendisse id sem eu neque mollis
                    feugiat quis vel elit. Vivamus consequat dictum libero eget
                    feugiat. Vestibulum sem orci, feugiat ut porttitor in,
                    vulputate ut ante. Aliquam arcu quam, dignissim eget lectus
                    et, gravida rhoncus felis. Maecenas semper tristique enim,
                    eget dictum sem accumsan accumsan.
                </p>
            </div>
        </section>
    );
};
