import React from "react";
import { analyticsEvent } from "../utils";

const AREA_CODE = 6101;

export default ({ to, className }) => (
    <a
        className={className}
        href={`tel:+49${AREA_CODE}${to}`}
        onClick={() =>
            analyticsEvent("tel", "click", `+49 (${AREA_CODE}) ${to}`)
        }
    >
        +49&nbsp;({AREA_CODE})&nbsp;{to}
    </a>
);
