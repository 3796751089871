import React from "react";
import { css } from "@emotion/react";
import Img from "gatsby-image";
import MailLink from "./MailLink";
import TelLink from "./TelLink";
import { fontSize28, fontSize14 } from "../style/fonts";
import { mediumGreen } from "../style/colors";
import {
    mediaSmall,
    fontSizeText,
    mediumGreenHover,
    mediaMobile,
} from "../style/shared";

const nameStyle = css`
    ${fontSize28};
    color: ${mediumGreen};
    margin-bottom: 8px;
`;

const titleStyle = css`
    ${fontSizeText};
    font-style: italic;
    margin-bottom: 8px;
`;

const descriptionStyle = css`
    ${fontSize14};
    margin-bottom: 8px;
    text-aling: justify;
`;

const linkStyle = css`
    display: block;
    margin-bottom: 2px;
    ${mediumGreenHover};
    ${fontSize14};
`;

const employeeStyle = css`
    flex: none;
    margin: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 525px;

    ${mediaMobile} {
        display: block;
        width: 100%;
    }
`;

const columnStyle = `
  flex: none;
`;

const leftColumnStyle = css`
    width: calc(62% - 8px);

    ${mediaSmall} {
        width: calc(55% - 8px);
        padding-bottom: 16px;
    }

    ${mediaMobile} {
        width: 100%;
    }

    ${columnStyle};
`;

const rightColumnStyle = css`
    width: calc(38% - 8px);

    ${mediaSmall} {
        width: calc(45% - 8px);
        padding-top: 16px;
    }

    ${mediaMobile} {
        width: 100%;
        max-width: 250px;
    }

    ${columnStyle};
`;

export default ({ name, title, description, tel, mail, image }) => (
    <div css={employeeStyle}>
        <div css={leftColumnStyle}>
            <h3 css={nameStyle}>{name}</h3>
            <p css={titleStyle}>{title}</p>
            <p css={descriptionStyle}>{description}</p>
            <TelLink css={linkStyle} to={tel} />
            <MailLink css={linkStyle} to={mail} />
        </div>
        <div css={rightColumnStyle}>
            <Img fluid={image} alt={name} />
        </div>
    </div>
);
