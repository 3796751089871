import React from "react";
import { css } from "@emotion/react";
import Empolyee from "../components/Empolyee";
import CtaBanner from "../components/CtaBanner";
import { graphql } from "gatsby";
import {
    centeredBlock,
    fontSizeHeader,
    mediaMaxPageWidth,
} from "../style/shared";
import { mediumGreen } from "../style/colors";
import OfficeSection from "../components/OfficeSection";

const aboutStyle = css`
    ${centeredBlock};
    padding-top: 64px;
    padding-bottom: 64px;
`;

const headerStyle = css`
    color: ${mediumGreen};
    ${fontSizeHeader};
    margin-bottom: 32px;
`;
const columnStyle = css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${mediaMaxPageWidth} {
        display: block;
    }
`;

export default ({ data }) => (
    <React.Fragment>
        <OfficeSection />
        <div css={aboutStyle}>
            <h2 css={headerStyle}>Unser Team</h2>
            <div css={columnStyle}>
                <Empolyee
                    name="Oliver Schmitt"
                    title="Steuerberater"
                    description="Praesent dignissim turpis quis est molestie interdum. Integer consequat quis sapien et feugiat. Donec eget sodales erat. Duis at justo libero. Donec nec metus bibendum, porttitor dui vitae, feugiat enim."
                    image={data.ruediger.childImageSharp.fluid}
                    mail="os"
                    tel="800660"
                />
                <Empolyee
                    name="Rüdiger Mustermann"
                    title="Steuerfachgehilfe"
                    description="Rüdiger Mustermann ist schon seit über siebzig Jahren ein zuverlässiger Mitarbeiter der sich stets sehr bemüht. Sein Spezialgebiet sind halblegale, unprofessionelle Nebbenkostenabrechungen."
                    image={data.ruediger.childImageSharp.fluid}
                    mail="rm"
                    tel="12345"
                />
                <Empolyee
                    name="Rüdiger Mustermann"
                    title="Steuerfachgehilfe"
                    description="Rüdiger Mustermann ist schon seit über siebzig Jahren ein zuverlässiger Mitarbeiter der sich stets sehr bemüht. Sein Spezialgebiet sind halblegale, unprofessionelle Nebbenkostenabrechungen."
                    image={data.ruediger.childImageSharp.fluid}
                    mail="rm"
                    tel="12345"
                />
            </div>
        </div>
        <CtaBanner
            image={data.ctaDog.childImageSharp.fixed}
            alt="Wohlbehütetes Hundebaby"
            angle={95}
            color="#1d1717"
            offset={-50}
            ctaType="tel"
            slogan="Bei uns sind Sie gut aufgehoben."
        />
    </React.Fragment>
);

export const query = graphql`
    query {
        ruediger: file(relativePath: { eq: "employee.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ctaDog: file(
            relativePath: { eq: "freestocks-org-119823-unsplash.jpg" }
        ) {
            childImageSharp {
                fixed(height: 420) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;
